<template>
  <div id="audit-form">
    <div class="audit-form-container">
      <div class="left-section">
        <div class="header-text">Power as a service</div>
        <div class="small-gray-text">
          At Imperium, we take care of all your power and energy concerns by empowering your homes, stores and
          businesses with our clean energy solutions. By selecting our “Power as a Service” option, you are guaranteed
          clean and reliable power without the burden and hassle of installing, maintaining or even owning a solar/power
          solution. Begin to enjoy power today at zero upfront cost. You only pay an agreed tariff based on the solution
          deployed.
        </div>
      </div>
      <div class="right-section">
        <form class="form" @submit.prevent="submit">
          <div class="form-row">
            <div class="form-group">
              <label for="first name">First Name</label>
              <input type="text" v-model="formData.first_name" name="" class="input-field" required />
            </div>
            <div class="form-group">
              <label for="last name">Last Name</label>
              <input type="text" v-model="formData.last_name" name="" class="input-field" required />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group">
              <label for="email">Email Address</label>
              <input type="email" v-model="formData.email" name="" class="input-field" required />
            </div>
            <div class="form-group">
              <label for="phone number">Phone Number</label>
              <input type="number" v-model="formData.phone_number" name="" class="input-field" required />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group">
              <label for="contact preference">Contact Preference</label>
              <select v-model="formData.contact_reference" class="input-field" required>
                <option value="" selected disabled>Select preference</option>
                <option value="phone">Phone</option>
                <option value="email">Email</option>
                <option value="phone-email">Both Phone and Email</option>
              </select>
            </div>
            <div class="form-group">
              <label for="contact date">Contact Date</label>
              <Datepicker
                :wrapper-class="'vue-datepicker'"
                :input-class="'vue-datepicker-input'"
                :disabled-dates="disabledDates"
                :highlighted="highlightedDay"
                v-model="formData.contact_date"
                placeholder="Select date"
                name="date"
              ></Datepicker>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group">
              <label for="house number">House number</label>
              <input type="text" v-model="formData.address.house_number" name="" class="input-field" required />
            </div>
            <div class="form-group">
              <label for="street name">Street name</label>
              <input type="text" v-model="formData.address.street" name="" class="input-field" required />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group">
              <label for="local government area">LGA</label>
              <input type="text" v-model="formData.address.lga" name="" class="input-field" required />
            </div>
            <div class="form-group">
              <label for="state">State</label>
              <select name="state" class="input-field" required v-model="formData.address.state">
                <option value="" selected disabled>Select state</option>
                <option value="Abuja FCT">Abuja FCT</option>
                <option value="Abia">Abia</option>
                <option value="Adamawa">Adamawa</option>
                <option value="Akwa Ibom">Akwa Ibom</option>
                <option value="Anambra">Anambra</option>
                <option value="Bauchi">Bauchi</option>
                <option value="Bayelsa">Bayelsa</option>
                <option value="Benue">Benue</option>
                <option value="Borno">Borno</option>
                <option value="Cross River">Cross River</option>
                <option value="Delta">Delta</option>
                <option value="Ebonyi">Ebonyi</option>
                <option value="Edo">Edo</option>
                <option value="Ekiti">Ekiti</option>
                <option value="Enugu">Enugu</option>
                <option value="Gombe">Gombe</option>
                <option value="Imo">Imo</option>
                <option value="Jigawa">Jigawa</option>
                <option value="Kaduna">Kaduna</option>
                <option value="Kano">Kano</option>
                <option value="Katsina">Katsina</option>
                <option value="Kebbi">Kebbi</option>
                <option value="Kogi">Kogi</option>
                <option value="Kwara">Kwara</option>
                <option value="Lagos">Lagos</option>
                <option value="Nassarawa">Nassarawa</option>
                <option value="Niger">Niger</option>
                <option value="Ogun">Ogun</option>
                <option value="Ondo">Ondo</option>
                <option value="Osun">Osun</option>
                <option value="Oyo">Oyo</option>
                <option value="Plateau">Plateau</option>
                <option value="Rivers">Rivers</option>
                <option value="Sokoto">Sokoto</option>
                <option value="Taraba">Taraba</option>
                <option value="Yobe">Yobe</option>
                <option value="Zamfara">Zamfara</option>
                <option value="Outside Nigeria">Outside Nigeria</option>
              </select>
            </div>
          </div>
          <div class="btn-full-green">
            <button>Send Request</button>
            <small>By clicking the button, you agree to be contacted by the Imperium team.</small>
          </div>
        </form>
      </div>
    </div>
    <div>
      <svg
        class="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shape-rendering="auto"
      >
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g class="parallax">
          <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(197,217,135)" />
          <use xlink:href="#gentle-wave" x="48" y="1" fill="rgba(194,225,170)" />
          <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(175,212,125, 0.5)" />
          <use xlink:href="#gentle-wave" x="48" y="7" fill="rgb(175,212,125)" />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import Api from "@/utils/api.js";
export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      // selectedCity: "",
      // selectedState: "",
      // selectedContactMode: "",
      // selectedDate: "",
      disabledDates: {
        to: "",
      },
      highlightedDay: {
        dates: [],
      },
      formData: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        contact_reference: "",
        contact_date: "",
        address: {
          house_number: "",
          street: "",
          lga: "",
          state: "",
        },
      },
    };
  },
  created() {
    this.presentDate();
  },
  methods: {
    goTo(page) {
      this.$router.push(page);
    },
    presentDate() {
      let dateObj = new Date();
      let month = dateObj.getMonth();
      let day = String(dateObj.getDate()).padStart(2, "0");
      let year = dateObj.getFullYear();
      this.disabledDates.to = new Date(year, month, day);
      this.highlightedDay.dates = [new Date(year, month, day)];
    },
    submit(event) {
      Api.sendAuditForm(this.formData)
        .then((response) => {
          this.$message({
            showClose: true,
            message: response.data.data.message,
            type: "success",
          });
          event.target.reset();
        })
        .catch(({ response }) => {
          this.$message({
            showClose: true,
            message: response.data.status,
            type: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss">
// for vue date picker classes to take effect, the scoped attribute has to be removed
.vue-datepicker {
  background: #0000000f;
  padding: 0.55rem;
  border-radius: 5px;

  .vue-datepicker-input {
    width: 100%;
    border: none;
    outline: none;
    color: #414b55;
    background-color: transparent;
  }
}
</style>
<style lang="scss" scoped>
@import "../assets/styles/sass/audit-form";
</style>
