<template>
  <div>
    <toggle-switch></toggle-switch>
    <section id="gen-capacity" class="calculator-container">
      <div class="header-text">What’s your generator’s capacity</div>
      <div class="small-text">We all want a healthy planet. The small choices we make each day can help us get there.</div>
      <img src="../assets/img/generator.png" class="img-gen">
      <input type="range" min="1" max="1000" v-model="generatorCapacity" class="slider slider-pink" id="myRange">
      <div class="text-field-and-dropdown-box border-red">
        <input class="text-field" v-model="generatorCapacity">
        <div class="select-container bg-red">
          <select name="">
            <option value="volvo">Watts</option>
            <option value="saab">KiloWatts</option>
          </select>
        </div>
      </div>
      <calculator-nav-button bg="#d00202" @click="goTo('generator-fueling-cost')">Next</calculator-nav-button>
    </section>
  </div>
</template>

<script>

import CalculatorNavButton from '../components/CalculatorNavButton.vue';
import ToggleSwitch from '../components/ToggleSwitch.vue';

export default {
  components: {
    CalculatorNavButton,
    ToggleSwitch
  },
  data () {
    return {
       generatorCapacity: 100,
    }
  },
  methods: {
    goTo(page) {
      this.$router.push(page); 
    },

  }
  
}
</script>

<style lang="scss" scoped>

@import "../assets/styles/sass/calculator";

</style>
