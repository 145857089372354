<template>
  <div id="app">
    <top-nav />
    <router-view></router-view>
  </div>
</template>

<script>
import TopNav from "../src/components/TopNav";

export default {
  name: "app",
  components: {
    "top-nav": TopNav
  }
};
</script>

<style lang="scss">
@import "./assets/styles/sass/main";
</style>
