<template>
  <div>
    <toggle-switch></toggle-switch>
    <section id="first-section">
      <div id="light-bulb-group">
        <img src="../assets/img/light-bulb-red.png" class="img-bulb" />
        <img
          src="../assets/img/light-bulb-yellow.png"
          class="img-bulb bulb-center"
        />
        <img src="../assets/img/light-bulb-green.png" class="img-bulb" />
      </div>
      <input
        type="range"
        min="1"
        max="100"
        value="0"
        class="slider slider-green"
        id="myRange"
      />
      <div class="big-text">
        At Imperium, we believe every big impact starts with small changes
      </div>
      <div class="small-gray-text">
        We all want a healthy planet. The small choices we make each day can
        help us get there. Learn simple tips from Google and the California
        Academy of Sciences to leave the earth in better shape.
      </div>
      <button @click="goTo('/generator-on-time')" class="button-green">
        Start the calculator
      </button>
    </section>
  </div>
</template>

<script>
import ToggleSwitch from "../components/ToggleSwitch.vue";

export default {
  components: {
    ToggleSwitch
  },
  data() {
    return {
      // msg: 'Welcome to Your Vue.js App'
    };
  },
  methods: {
    goTo(page) {
      this.$router.push(page);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/sass/calculator";
</style>
