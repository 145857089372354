<template>
  <div id="switch-container">
    <div class="small-light-text">Switch to form</div>
    <label class="switch">
      <input type="checkbox" @click="switchToFormBasedCalculator()">
      <span class="slider round"></span>
    </label>
    <div class="row"></div>
  </div>
</template>

<script>

export default {
  props: {
      bg: {
        type: String,
        default: '#f5a623',
      }
  },
  
  methods: {
    switchToFormBasedCalculator(){
      setTimeout(() => {
        this.$router.push('/form-based');
    }, 800);}
  },
}

</script>

<style lang="scss" scoped>

@import "../assets/styles/sass/toggle-switch";

</style>
