import axios from "axios";

const BASE_URL = process.env.VUE_APP_BASE_URL;
const DEVICE_URL = `${BASE_URL}/device`;
const CALCULATION_URL = `${BASE_URL}/calculation`;
const PRODUCTS_URL = `${BASE_URL}/product`;

export default {
  getAppliance() {
    return axios.get(`${DEVICE_URL}`);
  },
  getFuelCostPerLitre() {
    return axios.get(`${CALCULATION_URL}/fuel-prices`);
  },
  costAndEnergyConsumptionGenerator: (generator) => {
    return axios.post(`${CALCULATION_URL}/generator`, generator);
  },
  costAndEnergyConsumptionDevice: (devices) => {
    return axios.post(`${CALCULATION_URL}/device`, devices);
  },
  getProductByCategory: (category, page) => {
    return axios.get(`${PRODUCTS_URL}?category=${category}&per_page=${page}`);
  },
  sendAuditForm: (formData) => {
    return axios.post(`${BASE_URL}/power`, formData);
  },
};
