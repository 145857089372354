<template>
  <div>
    <div id="form-calculator">
      <div id="main-content">
        <section class="left-section">
          <div class="left-section-content">
            <div class="general-calculation" v-if="formGeneralIsActive">
              <img src="../assets/img/home.png" class="img-home">
              <div class="header-text">General Calculation</div>
              <div class="small-gray-text">Enter your energy consumption rate and we will recommend the best renewable energy providers for you.</div>
            </div>
            <div class="application-base" v-else>
              <img src="../assets/img/appliances.png" class="img-appliance-small">
              <div class="header-text">Appliance Based</div>
              <div class="small-gray-text">Enter your energy consumption rate and we will recommend the best renewable energy providers for you.</div>
            </div>
            <div class="btn">  
              <button @click="goTo('/request-audit')">Request Energy Audit</button>
            </div>
          </div>
        </section>
        <section class="middle-section">
          <div class="form-box">
              <div class="form-header">
                <div class="header-text">Your Calculation</div>
                <button type="button" class="button-general bg-green" @click="switchFormToGeneral(true)" ref="generalBasedButton">Generator Based</button>
                <button type="button" class="button-device-based" @click="switchFormToGeneral(false)" ref="deviceBasedButton">Device Based</button>
              </div>
              <form v-if="formGeneralIsActive" @submit.prevent="calculateCostAndEnergyConsumption()">
                <div class="form-body">
                  <div class="text-dropdown-field">
                    <select class="full-width text-align-left" required @change="onChangeFuelType($event)">
                      <option value="" disabled selected>Fuel type</option>
                      <option value="petrol">Petrol</option>
                      <option value="diesel">Diesel</option>
                    </select>
                  </div>
                  <div class="form-label">What's your generator capacity?</div>
                  <div class="text-dropdown-field" >
                    <input type="number" placeholder="" class="text-field" v-model="generator.size" required>
                    <span>
                    <select class="select-disabled" disabled>
                      <option value="kva">KVA</option>
                    </select>
                    </span>
                  </div>
                  <div class="form-label">What's your daily generator on-time?</div>
                  <div class="text-dropdown-field">
                    <input type="number" placeholder="" class="text-field" v-model="generator.on_time" required>
                    <span>
                      <select class="select-disabled" disabled>
                        <option value="hours">Hrs/day</option>
                      </select>
                    </span>
                  </div>
                  <div class="form-label" v-if="fuelType === 'diesel'">Percentage loading in gallons/hour?</div>
                  <div class="text-dropdown-field" v-if="fuelType === 'diesel'">
                    <select class="full-width text-align-left" required @change="onDieselPercentageLoadingChange($event)">
                      <option value="" disabled selected></option>
                      <option value="25">25%</option>
                      <option value="50">50%</option>
                      <option value="50">75%</option>
                      <option value="50">100%</option>
                    </select>
                  </div>
                  <div class="form-label">Period of usage in months</div>
                  <div class="text-dropdown-field">
                    <input type="number" placeholder="" class="text-field full-width" v-model="generator.period_of_usage" required>
                  </div>  
                </div>
                <Button class="btn-full-green">Compute</Button>
              </form>
              <form v-else @submit.prevent="addAppliance()">
                <div class="form-body">
                  <div class="text-dropdown-field">
                    <select class="full-width text-align-left" ref="applianceName" @change="onDeviceItemChange($event)" required>
                      <option value="" disabled selected>Choose a device</option> 
                      <option v-for="(appliance, index) in appliances" :key="index">
                        <option>{{appliance.name}}</option> 
                      </option>
                    </select>
                  </div> 
                  <div class="form-label">Power rating</div>
                  <div class="text-dropdown-field" >
                    <input type="number" step="any" placeholder="" class="text-field" ref="capacityNum" v-model="capcityOfSelectedDevice" disabled required>
                    <select ref="powerMeasurment" disabled>
                      <option value="watts">Watts</option>
                    </select>
                  </div>
                  <div class="form-label">Quantity</div>
                  <div class="text-dropdown-field">
                    <input type="number" placeholder="" class="text-field full-width" ref="quantity" required>
                  </div>
                  <div class="form-label">Hours of usage daily</div>
                  <div class="text-dropdown-field">
                    <input type="number" placeholder="" class="text-field full-width" ref="dailyUsageHours" required>
                  </div>
                </div>
                <Button class="btn-full-green" ref="buttonAddAppliance">Add</Button>
              </form>
          </div>
        </section>
        <section class="right-section">
          <div class="form-box">
            <div class="form-body" v-if="formGeneralIsActive">  
              <div class="header-text">Maintenance Info</div>
            <div class="form-label">Price of generator</div>
              <div class="text-dropdown-field">
                <input type="text" placeholder="" class="text-field" v-model="priceOfGenerator" readonly>
              </div>
              <div class="form-label">Monthly maintenace cost</div>
              <div class="text-dropdown-field">
                <input type="text" placeholder="" class="text-field" v-model="monthlyMaintenanceCost" readonly>
              </div>
              <div class="form-label">Cost of fuel per litre</div>
              <div class="text-dropdown-field">
                <input type="text" placeholder="" class="text-field" v-model="costOfFuelPerLitre" readonly>
              </div>
            </div>
            <div id="appliances-list-view" class="form-body" v-else>  
              <div class="header-text">Your appliances</div>
              <div class="appliance-view-rect" v-for="(device, index) in devices" :key="index" v-bind:class="{ float_right: ( (index % 2) > 0) }">
                <font-awesome-icon icon="times" class="icon-cancel" @click="removeAppliance(index)"/>
                <div class="appliance-details"> 
                  <div>{{ device.name }}</div>
                  <span>{{ device.quantity }}</span>,
                  <span> {{device.size}} kw</span>,
                  <span> {{ device.on_time }} hrs daily</span>
                </div>
              </div>
            </div>
            <button class="btn-full-green" @click="calculateCostAndEnergyConsumption()" v-if="!formGeneralIsActive">Proceed</button>
          </div>
        </section>
      </div>
    <div>
      <svg
        class="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shape-rendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g class="parallax">
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="0"
            fill="rgba(197,217,135)"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="1"
            fill="rgba(194,225,170)"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="5"
            fill="rgba(175,212,125, 0.5)"
          />
          <use xlink:href="#gentle-wave" x="48" y="7" fill="rgb(175,212,125)" />
        </g>
      </svg>
    </div>
    </div>
  </div>
</template>

<script>
import api from "../utils/api";

export default {
  data () {
    return {
      formGeneralIsActive: true,
      fuelType: false,
      costOfFuelPerLitre: '',
      priceOfGenerator: null,
      monthlyMaintenanceCost: null,
      pertrol_per_litre: null,
      diesel_per_litre: null,

      appliance: {
        size: null,
        name: "",
        on_time: 0,
        quantity: 0

      },
      
      devices: [],
      appliances: null,
      capcityOfSelectedDevice : null,

      generator: {
        size: null,
        cost_per_litre: null,
        type: '',
        on_time: null,
        name: 'generator',
        quantity: 1,
        period_of_usage: null,
        load: 0.25
      },
    }
  },

  watch: {
    'generator.size': function () {
      this.computeGeneratorPrice();
    },

    'fuelType': function () {
       this.computeGeneratorPrice();
    }

  },

  mounted() {
    this.getAppliances()
    this.getFuelCostPerLitre()
  },

  methods: {
    goTo(page) {
      this.$router.push(page); 
    },

    getAppliances(){
      api
      .getAppliance()
      .then(response => {
        this.appliances = response.data.data
      })
              .catch(({ response }) => {
            alert(`${response.data.status}`)
        });
    },
    getFuelCostPerLitre(){
      api
      .getFuelCostPerLitre()
      .then(response => {
        this.pertrol_per_litre = response.data.data.GASOLINE_PER_LITRE;
        this.diesel_per_litre = response.data.data.DIESEL_PER_LITRE;
      })
              .catch(({ response }) => {
            alert(`${response.data.status}`)
        });
    },

    computeGeneratorPrice(){
      const multiplier = (this.fuelType === 'diesel')? 90000 : 30000;
      let genPrice = multiplier * this.generator.size;

      this.priceOfGenerator =  genPrice.toLocaleString('NGN', {style: 'currency', currency: 'NGN'});
      this.priceOfGenerator = (this.priceOfGenerator == 'NGN 0.00')? null : this.priceOfGenerator;

      this.monthlyMaintenanceCost = (0.015 * genPrice).toLocaleString('NGN', {style: 'currency', currency: 'NGN'});
      this.monthlyMaintenanceCost = (this.monthlyMaintenanceCost == 'NGN 0.00')? null : this.monthlyMaintenanceCost;
    },

    switchFormToGeneral(val){
      this.formGeneralIsActive = val;
      
      if(this.formGeneralIsActive){
        this.$refs.generalBasedButton.classList.add("bg-green");
        this.$refs.deviceBasedButton.classList.remove("bg-green");
        this.clearApplianceInputFields()
        this.clearAddedAppliance()
      }
      else{
        this.$refs.deviceBasedButton.classList.add("bg-green");
        this.$refs.generalBasedButton.classList.remove("bg-green");
        this.clearGeneratorInputField()
        this.clearMaintenanceInfo()
      }
    },

    clearGeneratorInputField() {
      this.generator.size = null
      this.generator.on_time = null
      this.generator.period_of_usage = null
    },
    clearMaintenanceInfo() {
      this.priceOfGenerator = null
      this.monthlyMaintenanceCost = null
      this.costOfFuelPerLitre = ''
    },

    addAppliance(){
      let appliance = this.$refs.applianceName;

      let name = appliance.options[appliance.selectedIndex].value;
      let size = parseFloat(this.$refs.capacityNum.value);
      let powerMeasurment = this.$refs.powerMeasurment.value;
      let quantity = parseInt(this.$refs.quantity.value);
      let on_time = parseInt (this.$refs.dailyUsageHours.value);

      this.appliance = { size, name, on_time, quantity}
      this.devices.push(this.appliance);

      this.clearApplianceInputFields();
    },

    clearApplianceInputFields(){
      this.$refs.applianceName.selectedIndex = 0;
      this.$refs.capacityNum.value = this.$refs.powerMeasurment.value = this.$refs.quantity.value = this.$refs.dailyUsageHours.value = this.capcityOfSelectedDevice = null;
    },

    clearAddedAppliance() {
      this.devices = []
    },

    removeAppliance(index){
      this.devices.splice(index, 1);
    },

    switchToInteractionBasedCalculator(){
      setTimeout(() => {
        this.$router.push('/'); 
      }, 800);
      
    },

    onDeviceItemChange(event){
      this.capcityOfSelectedDevice = this.appliances[event.target.selectedIndex - 1].rating;
    },

    onChangeFuelType(event){
      this.fuelType = event.target.value;
      if(this.fuelType === 'diesel') {
        this.costOfFuelPerLitre = '₦ '+this.diesel_per_litre+' / litre'
      } else if(this.fuelType === 'petrol') {
        this.costOfFuelPerLitre = '₦ '+this.pertrol_per_litre+' / litre'
      } else {
        this.costOfFuelPerLitre = ''
      }
       this.generator.type = this.fuelType === 'diesel'? "diesel" : "gasoline"
    },

    onDieselPercentageLoadingChange(){
      this.generator.load = event.target.value/100;
    },

    calculateCostAndEnergyConsumption(){
      if(this.formGeneralIsActive){
        localStorage.calculationType = 'genrator-based';
        api
        .costAndEnergyConsumptionGenerator(this.generator)
        .then(({data}) => {
            localStorage.monthlyMaintenanceCost = data.data.monthlyMaintenanceCost;
            localStorage.yearlyMaintenanceCost = data.data.yearlyMaintenanceCost;
            localStorage.totalMaintenanceCost = data.data.totalMaintenanceCost;

            localStorage.fuelCostPerWeek = data.data.fuelCostPerWeek;
            localStorage.fuelCostPerMonth = data.data.fuelCostPerMonth;
            localStorage.fuelCostPerYear = data.data.fuelCostPerYear;

            this.$router.push('/calculator-results'); 
        })
          .catch(({ response }) => {
            alert(`${response.data.status}`)
          })

      }
      else{
        if(this.devices.length === 0){
          alert('Please add devices')
          return
        }else {
          localStorage.calculationType = 'device-based';
          api
          .costAndEnergyConsumptionDevice({'devices': this.devices})
          .then(({data}) => {
              localStorage.totalDeviceEnergy = data.data.totalDeviceEnergy;
              localStorage.energyConsumptionPerMonth = data.data.energyConsumptionPerMonth;
              localStorage.energyConsumptionPerWeek = data.data.energyConsumptionPerWeek;
              localStorage.energyConsumptionPerYear = data.data.energyConsumptionPerYear;
  
              this.$router.push('/calculator-results'); 
          })
          .catch(({ response }) => {
              alert(`${response.data.status}`)
          });
        }

      }

    }

  },

}
</script>

<style lang="scss" scoped>
@import "../assets/styles/sass/calculator-form";
</style>
