import Index from "./pages/Index.vue";
import GeneratorOnTime from "./pages/GeneratorOnTime.vue";
import GeneratorCapacity from "./pages/GeneratorCapacity.vue";
import GeneratorFuelingCost from "./pages/GeneratorFuelingCost.vue";
import CalculatorResults from "./pages/CalculatorResults.vue";
import FormBasedCalculator from "./pages/FormBasedCalculator.vue";
import RequestAuditForm from "./pages/RequestAuditForm.vue";

export const routes = [
  // { path: '', component: Index },
  { path: "", component: FormBasedCalculator },
  // { path: '/form-based', component: FormBasedCalculator},
  { path: "/index", component: Index },
  { path: "/generator-on-time", component: GeneratorOnTime },
  { path: "/generator-capacity", component: GeneratorCapacity },
  { path: "/generator-fueling-cost", component: GeneratorFuelingCost },
  { path: "/calculator-results", component: CalculatorResults },
  { path: "/request-audit", component: RequestAuditForm }
];
