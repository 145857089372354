<template>
  <div>
    <div class="desktop-view">
      <section id="results-section">
        <div class="header-text">Your result</div>
        <div class="consumption-cost">
          <div class="device-base" v-if="calculationType == 'device-based'">
            <div class="left-section">
              <div class="box">
                <div class="number">{{ energyConsumption }}</div>
                <div class="text">Your energy consumption</div>
              </div>
            </div>
            <div class="right-section">
              <select name="" @change="onDurationChange($event)">
                <option value="Weekly">Weekly</option>
                <option value="Monthly" selected>Monthly</option>
                <option value="Yearly">Yearly</option>
              </select>
            </div>
          </div>
          <div class="generator-base" v-else>
            <div class="left-section">
              <div class="box center-900">
                <div class="number">₦ {{ totalOperatingCost }}</div>
                <div class="text">Total operating cost</div>
              </div>
              <div class="box">
                <div class="number">₦ {{ fuelCost }}</div>
                <div class="text desktop-view">Total fueling cost</div>
                <div class="text mobile-view">Fueling cost</div>
              </div>
              <div class="box border-right-none border-left">
                <div class="number">₦ {{ maintenanceCost }}</div>
                <div class="text desktop-view">Total maintenance cost</div>
                <div class="text mobile-view truncate-name">
                  Maintenance cost
                </div>
              </div>
            </div>
            <div class="right-section">
              <select name="" @change="onDurationChange($event)">
                <option value="Weekly">Weekly</option>
                <option value="Monthly" selected>Monthly</option>
                <option value="Yearly">Yearly</option>
              </select>
            </div>
          </div>
        </div>
        <div class="restart-save-buttons-section">
          <div>
            <router-link to="/">Restart calculator</router-link>
          </div>
        </div>
      </section>
      <section class="section-product-alternatives">
        <div class="header-text desktop-view">
          Suggested products based on your consumption
        </div>
        <div class="header-text mobile-view">
          Suggested products
        </div>
        <ContentLoader v-if="loading">
          <div v-if="show" class="loader"></div>
          <span v-else>{{ contentLoaderText }}</span>
        </ContentLoader>
        <div class="products-group" v-else>
          <div
            class="product-box"
            v-for="(product, index) in suggestedProduct"
            :key="index"
            @click="viewProduct(product.slug, product.id)"
          >
            <div class="centered-content" v-if="product.display_image">
              <div class="img-container">
                <img :src="product.display_image" />
              </div>
              <div class="product-name truncate-name">{{ product.name }}</div>
              <div class="product-capacity">{{ product.capacity }} KV</div>
              <div class="price">₦ {{ product.price.toLocaleString() }}</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import api from "@/utils/api";
import shuffleArray from "@/utils/shuffleArray.js";
import ContentLoader from "@/components/ContentLoader";

export default {
  components: {
    ContentLoader
  },
  data() {
    return {
      totalOperatingCost: null,
      energyConsumption: 0,
      energyConsumptionPerMonth: 0,
      energyConsumptionPerWeek: 0,
      energyConsumptionPerYear: 0,
      fuelCost: null,
      fuelCostPerYear: null,
      fuelCostPerWeek: null,
      fuelCostPerMonth: 0,
      maintenanceCost: null,
      monthlyMaintenanceCost: null,
      yearlyMaintenanceCost: null,
      totalMaintenanceCost: null,
      weeklyMaintenanceCost: null,
      calculationType: null,
      totalCost: "",
      suggestedProduct: [],
      shopURL: process.env.VUE_APP_SHOP_URL,
      contentLoaderText: "Nothing to show",
      show: true,
      loading: true
    };
  },
  mounted() {
    this.calculationType = localStorage.calculationType;

    if (this.calculationType == "device-based") {
      this.energyConsumption = localStorage.energyConsumptionPerMonth;
      this.energyConsumptionPerMonth = localStorage.energyConsumptionPerMonth;
      this.energyConsumptionPerWeek = localStorage.energyConsumptionPerWeek;
      this.energyConsumptionPerYear = localStorage.energyConsumptionPerYear;

      this.fuelCost = this.fuelCostPerYear = this.fuelCostPerWeek = this.fuelCostPerMonth =
        "Not applicable";
    } else {
      this.fuelCostPerMonth = localStorage.fuelCostPerMonth;
      this.fuelCostPerWeek = localStorage.fuelCostPerWeek;
      this.fuelCostPerYear = localStorage.fuelCostPerYear;

      this.monthlyMaintenanceCost = this.totalCost =
        localStorage.monthlyMaintenanceCost;
      this.yearlyMaintenanceCost = localStorage.yearlyMaintenanceCost;
      this.totalMaintenanceCost = localStorage.totalMaintenanceCost;
      this.weeklyMaintenanceCost = String(
        Math.round(parseInt(this.monthlyMaintenanceCost.replace(/,/g, "")) / 4)
      ).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      this.fuelCost = this.fuelCostPerMonth;
      this.maintenanceCost = this.monthlyMaintenanceCost;

      this.totalOperatingCost = String(
        parseInt(this.fuelCost.replace(/,/g, "")) +
          parseInt(this.maintenanceCost.replace(/,/g, ""))
      ).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    this.getProductByCategory();
  },
  methods: {
    goTo(page) {
      this.$router.push(page);
    },

    viewProduct(slug, id) {
      if (slug === undefined) {
        return;
      } else {
        let url = `${this.shopURL}/product/${slug}/id/${id}`;
        window.location.href = url;
      }
    },

    getProductByCategory() {
      api
        .getProductByCategory("bundle", 1000000)
        .then(response => {
          if (response.data.data.result.length === 0) {
            this.show = false;
            return;
          }
          if (response.data.data.result.length < 4) {
            let emptyProductSpace = 4 - response.data.data.result.length;
            let emptyObject = {};
            let emptyProductArray = new Array(emptyProductSpace).fill(
              emptyObject
            );
            this.suggestedProduct = shuffleArray(
              response.data.data.result
            ).concat(emptyProductArray);
            this.loading = false;
          } else {
            this.suggestedProduct = shuffleArray(
              response.data.data.result
            ).slice(-4);
            this.loading = false;
          }
        })
        .catch(({ response }) => {
          alert(`${response.data.status}`);
        });
    },

    onConsumptionPerDurationChange(event) {
      if (event.target.value == "Monthly") {
        this.energyConsumption = this.energyConsumptionPerMonth;
      } else if (event.target.value == "Weekly") {
        this.energyConsumption = this.energyConsumptionPerWeek;
      } else {
        this.energyConsumption = this.energyConsumptionPerYear;
      }
    },

    onDurationChange(event) {
      if (event.target.value == "Monthly") {
        this.energyConsumption = this.energyConsumptionPerMonth;
        this.maintenanceCost = this.monthlyMaintenanceCost;
        this.fuelCost = this.fuelCostPerMonth;
      } else if (event.target.value == "Weekly") {
        this.energyConsumption = this.energyConsumptionPerWeek;
        this.maintenanceCost = this.weeklyMaintenanceCost;
        this.fuelCost = this.fuelCostPerWeek;
      } else {
        this.energyConsumption = this.energyConsumptionPerYear;
        this.maintenanceCost = this.yearlyMaintenanceCost;
        this.fuelCost = this.fuelCostPerYear;
      }

      this.totalOperatingCost = String(
        parseInt(this.fuelCost.replace(/,/g, "")) +
          parseInt(this.maintenanceCost.replace(/,/g, ""))
      ).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    onPaymentDuration(event) {
      if (event.target.value == "Monthly") {
        this.totalCost = this.monthlyMaintenanceCost;
      } else if (event.target.value == "Yearly") {
        this.totalCost = this.yearlyMaintenanceCost;
      }
      // else if(event.target.value == "Payment"){
      //   this.totalCost = "0,00";
      // }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/sass/calculator-result";
</style>
