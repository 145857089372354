<template>
  <div>
    <toggle-switch></toggle-switch>
    <section id="gen-fueling-cost" class="calculator-container">
      <div class="header-text">Cost of Fuelling</div>
      <div class="small-text">Typically the number of hours you keep your generator running per day</div>
      <img src="../assets/img/gas-fuel.png" class="img-gas-fuel">
      <div class="text-field-and-dropdown-box border-green">
        <input class="text-field" placeholder="NGN 0.00">
        <div class="select-container bg-green">
          <select name="">
            <option value="volvo">Petrol</option>
            <option value="saab">Diesel</option>
          </select>
        </div>
      </div>
      <calculator-nav-button bg="#59a358" @click="goTo('/calculator-results')">Complete</calculator-nav-button>
    </section>
  </div>
</template>

<script>

import CalculatorNavButton from '../components/CalculatorNavButton.vue';
import ToggleSwitch from '../components/ToggleSwitch.vue';

export default {
  components: {
    CalculatorNavButton,
    ToggleSwitch
  },

  data () {
    return {
      // msg: 'Welcome to Your Vue.js App'
      
    }
  },
  methods: {
    goTo(page) {
      this.$router.push(page); 
    },

  }
  
}
</script>

<style lang="scss" scoped>

@import "../assets/styles/sass/calculator";

</style>
