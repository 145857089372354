<template>
  <div id="top-nav">
    <div class="left-side">
      <a href="https://www.imperiumng.com/">
        <img src="../assets/img/img-logo.png" alt="logo" />
        <div class="title">Imperium</div>
      </a>
    </div>
    <div class="right-side">
      <a href="https://www.imperiumng.com/about/">About</a>
      <a href="/">Calculator</a>
      <a :href="shopURL">Shop</a>
      <a href="https://www.imperiumng.com/faqs/">Faqs</a>
      <a href="https://www.imperiumng.com/blog/">Blog</a>
    </div>
    <div class="mobile-right-side">
      <input type="checkbox" class="toggler" />
      <div class="hamburger"><div></div></div>
      <div class="menu">
        <div>
          <ul>
            <li>
              <a href="https://www.imperiumng.com/about">About</a>
            </li>
            <li>
              <a href="/">
                Calculator
              </a>
            </li>
            <li>
              <a :href="shopURL">
                Shop
              </a>
            </li>
            <li>
              <a href="https://www.imperiumng.com/faqs">
                Faqs
              </a>
            </li>
            <li>
              <a href="https://www.imperiumng.com/blog">
                Blog
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openMobileMenu: false,
      shopURL: process.env.VUE_APP_SHOP_URL
    };
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
    showMobileMenu() {
      this.openMobileMenu = !this.openMobileMenu;
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

@mixin transform-origin($x, $y) {
  -webkit-transform-origin: $x $y;
  -moz-transform-origin: $x $y;
  -ms-transform-origin: $x $y;
  -o-transform-origin: $x $y;
  transform-origin: $x $y;
}

@mixin transform-rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -o-transform: rotate($deg);
  -ms-transform: rotate($deg);
  transform: rotate($deg);
}

#top-nav {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  font-weight: 600;
  font-size: 18px;
  padding: 0 10%;
  padding-top: 1.5rem;
  margin-bottom: 1.2rem;

  @media screen and (max-width: 1200px) {
    padding: 0 5%;
    padding-top: 2rem;
  }

  .left-side {
    a {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      text-decoration: none;
    }

    img {
      width: 9%;
      height: 9%;
      object-fit: contain;
    }

    .title {
      opacity: 0.9;
      color: #1d1d1d;
      align-self: flex-end;
      margin-left: 0.7rem;
      padding-bottom: 0.2rem;
    }
  }

  .right-side {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    align-self: flex-end;
    padding-bottom: 0.2rem;

    a {
      margin-left: 33px;
      color: #1d1d1d;
      opacity: 0.7;
      text-decoration: none;
      transition: opacity 0.5s;
    }

    a:hover {
      color: #1d1d1d;
      opacity: 1;
      transition: opacity 0.5s;
    }
  }

  .mobile-right-side {
    display: none;
    position: fixed;
    font-size: 14px;
    right: 0;
    top: 0;
    z-index: 100;

    .toggler {
      position: absolute;
      right: 2rem;
      top: 1.2rem;
      z-index: 300;
      cursor: pointer;
      width: 1.8rem;
      height: 3rem;
      opacity: 0;
    }

    .hamburger {
      position: absolute;
      right: 2rem;
      top: 1.2rem;
      z-index: 200;
      cursor: pointer;
      width: 2rem;
      height: 3.2rem;
      padding: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    // hamburger line
    .hamburger > div {
      position: relative;
      width: 100%;
      height: 0.2rem;
      background-color: black;
      opacity: 0.6;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease;
    }
    .hamburger > div:before,
    .hamburger > div:after {
      content: "";
      position: absolute;
      top: -0.6rem;
      width: 100%;
      height: 0.2rem;
      z-index: 200;
      background-color: black;
      opacity: 0.6;
    }

    .hamburger > div:after {
      top: 0.6rem;
    }

    .toggler:checked + .hamburger > div {
      transform: rotate(135deg);
    }

    .toggler:checked + .hamburger > div:before,
    .toggler:checked + .hamburger > div:after {
      top: 0;
      transform: rotate(90deg);
    }

    .toggler:checked:hover + .hamburger > div {
      transform: rotate(225deg);
    }

    .menu {
      position: fixed;
      top: 4rem;
      right: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 3rem;
      padding-right: 2rem;
      // padding-left: 2rem;
      background-color: #f5f9f5;
      // opacity: 0;
      visibility: hidden;

      ul {
        list-style: none;
        li {
          padding-top: 2rem;

          a {
            text-decoration: none;
            color: black;
            opacity: 0.6;
            transition: opacity 1s;
          }
        }
      }

      a:hover {
        color: black;
        opacity: 1;
        transition: opacity 1s;
      }
    }

    .toggler:checked ~ .menu {
      visibility: visible;
    }
  }
}

@media screen and (max-width: 768px) {
  #top-nav {
    .right-side {
      display: none;
    }

    .mobile-right-side {
      display: block;
    }
  }
}
</style>
