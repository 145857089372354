<template>
  <div>
    <toggle-switch></toggle-switch>
    <section id="gen-on-time" class="calculator-container">
      <div class="header-text">What’s your generator’s ontime</div>
      <div class="small-text">Typically the number of hours you keep your  generator running per day</div>
      <img src="../assets/img/clock.png" class="img-clock">
      <input type="range" min="0" max="24" step="1" v-model="generatorOnTime" class="slider" id="myRange">
      <div class="text-field-and-dropdown-box">
        <input class="text-field" v-model="generatorOnTime">
        <div class="select-container">
          <select name="">
            <option value="">Hours</option>
            <!-- <option value="">Days</option> -->
          </select>
        </div>
      </div>
      <calculator-nav-button @click="goTo('generator-capacity')">Next</calculator-nav-button>
    </section>
  </div>
</template>

<script>

import CalculatorNavButton from '../components/CalculatorNavButton.vue';
import ToggleSwitch from '../components/ToggleSwitch.vue';

export default {
  components: {
    CalculatorNavButton,
    ToggleSwitch
  },

  data () {
    return {
      generatorOnTime: 0,
    }
  },
  
  methods: {
    goTo(page) {
      this.$router.push(page);
    },
    
  }
  
}
</script>

<style lang="scss" scoped>

@import "../assets/styles/sass/calculator";

</style>
