<template>
  <button class="calc-nav-button" @click="handleClick" :style="`background-color: ${bg}`">
     <slot />
  </button>
</template>

<script>

export default {
  methods: {
    handleClick() {
        this.$emit('click');
    },
  },
  props: {
      bg: {
        type: String,
        default: '#f5a623',
      }
  } 
}

</script>

<style lang="scss">

@import "../assets/styles/sass/calculator-nav-button";

</style>
